import React from "react";
import { motion } from "framer-motion";

const FadeANimatedSection = ({ children, delay }) => {
   return (
      <motion.div
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{
    opacity: { duration: 0.7, ease: "easeOut", delay: delay || 0 },  // Controls the fade-in
    rotate: { duration: 15, ease: "linear" },
    x: { duration: 15, ease: "easeInOut" }
}}
         style={{ overflow: "hidden" }}
      >
         {children}
      </motion.div>
   );
};

export default FadeANimatedSection;
