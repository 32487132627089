import { motion } from "framer-motion";

const AnimatedSection = ({ children, delay, triggerOnMount }) => {
   return (
      <motion.div
         initial={{ opacity: 0, y: 150 }} // Start from 150px below and fully transparent
         // Conditionally trigger based on whether triggerOnMount is true
         animate={triggerOnMount ? { opacity: 1, y: 0 } : undefined}
         whileInView={!triggerOnMount ? { opacity: 1, y: 0 } : undefined}
         transition={{ duration: 0.8, ease: "easeOut", delay: delay || 0 }}
         viewport={triggerOnMount ? undefined : { once: true }} // Only use viewport when not triggering on mount
      >
         {children}
      </motion.div>
   );
};

export default AnimatedSection;
