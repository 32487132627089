import React, { useEffect, useRef, useState } from "react";
import "../Style/Resource.css";
import AnimatedSection from "../Components/AnimatedSection";
import BackdropAnimation from "../Components/BackdropAnimation";
import BackdropGlow from "../Components/BackdropGlow";

const data = [
   {
      title: "Artificial Intelligence in PPC advertising.",
      image: "/images/Image1.png",
      url: "/images/AI in PPC advertising.pdf",
      description:
         "Discover how AI is reshaping PPC advertising. Learn about its benefits, challenges, and what it means for the future of your campaigns.",
   },
   {
      title: "The LLM Answer Quality Comparison Report.",
      image: "/images/img2.png",
      url: "/images/LLM Answer Quality Report.pdf",
      description:
         "Compare the performance of top language models in PPC. This report highlights how LLMs differ in accuracy and impact on ad campaigns.",
   },
   {
      title: "Launch a Campaign in 60 seconds.",
      image: "/images/img3.png",
      url: "/images/Launch a Campaign in 60 seconds.pdf",
      description:
         "This guide walks you through six steps using PPCC.AI. Learn how to go from idea to live campaign with efficiency, while maintaining quality.",
   },
];
const data2 = [
   {
      title: "Headlines",
      image: "/images/im1.png",
      url: "/headlines",
      description:
         "Our headlines: 30-characters max with clear formatting for readability. Short and simple, they drive strong results with minimal effort.",
   },
   {
      title: "Descriptions",
      image: "/images/im2.png",
      url: "/descriptions",
      description:
         "Four descriptions optimized for readability and consistency. Each of them follows guidelines, with capitalized keywords and simple language.",
   },
   {
      title: "Sitelinks",
      image: "/images/im3.png",
      url: "/sitelinks",
      description:
         "Allow AI to identify key areas of your website. Sitelinks include page names, URLs, and clear descriptions: adding more depth to your ad copy.",
   },
   {
      title: "Callouts",
      image: "/images/im4.png",
      url: "/callouts",
      description:
         "Reviewing key aspects of your URL, we deliver concise Callouts emphasizeing most relevant features designed to fit the character limit.",
   },
   {
      title: "Structured Snippets",
      image: "/images/im5.png",
      url: "/structured-snippets",
      description:
         "Snippets designed to align with Google’s predefined headers. Offering four values that provide additional detail on products or services.",
   },
];

const Resource = () => {
   const [activeIndex, setActiveIndex] = useState(0);
   const [enterpriseActiveIndex, setEnterpriseActiveIndex] = useState(0);
   const [heights, setHeights] = useState([]);
   const researchIntervalRef = useRef(null);
   const enterpriseIntervalRef = useRef(null);

   const blockCount = data.length;
   const enterpriseBlockCount = data2.length;

   const startResearchInterval = () => {
      researchIntervalRef.current = setInterval(() => {
         setActiveIndex((prevIndex) => (prevIndex + 1) % blockCount);
      }, 3000);
   };

   const clearResearchInterval = () => {
      if (researchIntervalRef.current) {
         clearInterval(researchIntervalRef.current);
         researchIntervalRef.current = null; // Reset the ref after clearing
      }
   };

   const startEnterpriseInterval = () => {
      enterpriseIntervalRef.current = setInterval(() => {
         setEnterpriseActiveIndex((prev) => (prev + 1) % enterpriseBlockCount);
      }, 3000);
   };

   const clearEnterpriseInterval = () => {
      if (enterpriseIntervalRef.current) {
         clearInterval(enterpriseIntervalRef.current);
         enterpriseIntervalRef.current = null;
      }
   };

   useEffect(() => {
      // const interval = setInterval(() => {
      //    setActiveIndex((prevIndex) => (prevIndex + 1) % blockCount);
      // }, 3000);

      // return () => clearInterval(interval);

      startResearchInterval();
      startEnterpriseInterval();

      return () => {
         clearResearchInterval();
         clearEnterpriseInterval();
      };
   }, []);

   useEffect(() => {
      const calculateHeights = () => {
         const btmDivs = document.querySelectorAll(".btm");
         const newHeights = Array.from(btmDivs).map((div) => div.scrollHeight);
         setHeights(newHeights);
      };

      calculateHeights();
      window.addEventListener("resize", calculateHeights);

      return () => {
         window.removeEventListener("resize", calculateHeights);
      };
   }, []);

   useEffect(() => {
  document.title = "AI and PPC Resources - Learn and Improve Ads | PPCC.ai";
  
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", "Find helpful Research Papers on PPC advertising and AI. Download Guides and Learn about AI-powered Search Ads. Stay ahead with PPCC AI.");
  }

  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute("content", "PPC advertising, ai search ads, google ads ai, create search ads");
  }

  // Open Graph tags
  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute("content", "AI and PPC Resources - Learn and Improve Ads | PPCC.ai");
  }

  const ogDescription = document.querySelector('meta[property="og:description"]');
  if (ogDescription) {
    ogDescription.setAttribute("content", "Find helpful Research Papers on PPC advertising and AI. Download Guides and Learn about AI-powered Search Ads. Stay ahead with PPCC AI.");
  }

  const ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute("content", "URL_to_image_for_preview");
  }

  const ogUrl = document.querySelector('meta[property="og:url"]');
  if (ogUrl) {
    ogUrl.setAttribute("content", "https://www.ppcc.ai/Resource");
  }

  // Twitter Card tags
  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (twitterTitle) {
    twitterTitle.setAttribute("content", "AI and PPC Resources - Learn and Improve Ads | PPCC.ai");
  }

  const twitterDescription = document.querySelector('meta[name="twitter:description"]');
  if (twitterDescription) {
    twitterDescription.setAttribute("content", "Find helpful Research Papers on PPC advertising and AI. Download Guides and Learn about AI-powered Search Ads. Stay ahead with PPCC AI.");
  }

  const twitterImage = document.querySelector('meta[name="twitter:image"]');
  if (twitterImage) {
    twitterImage.setAttribute("content", "URL_to_image_for_twitter");
  }

  const twitterCard = document.querySelector('meta[name="twitter:card"]');
  if (twitterCard) {
    twitterCard.setAttribute("content", "summary_large_image");
  }

}, []);


   return (
      <div className="main">
         <section className="banner banner-resources text-center ">
            <div className="d-md-block d-none">
               <BackdropAnimation
                  style={{
                     top: 150,
                     bottom: "inherit",
                     transform: "scale(0.7)",
                     left: 0,
                  }} singleElement={true}
               />
            </div>
            <div className="d-md-none d-block">
               <BackdropGlow />
            </div>
            <div className="desktoponly"><div className="container firstsectionre">
               <AnimatedSection delay={0.1}>
                  <div className="top-title mb-4 pb-1">RESOURCES</div>
               </AnimatedSection>
               <AnimatedSection delay={0.4}>
                  <h1 className="h1">
                     We present our findings here. We aim to be thorough, unbiased and always transparent.
                  </h1>
               </AnimatedSection>
               <AnimatedSection delay={0.5}>
                  <div className="desc mb-4 mt-3 resource-banner-title">
                     Our mission is to improve efficiency and accessibility of search engine advertising.
                  </div>
               </AnimatedSection>
            </div>
            <AnimatedSection>
               <div className=" py-4 threeicons">
                  <div className="cnt2 resource-col-main">
                     <div className="row align-items-center">
                        <div className="col-md-4">
                           <div class="container11">
                              <div class="icon1"><img src="/images/googlelogo.png" class="speedicon"/></div>
                              <div class="title">Prioritising Efficiency</div>
                              <div class="desc mb-4 p-16">
                              Efficiency is at the heart of our Google search ads process and approach. Discover, refine, and launch.
                              </div>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div class="container11">
                              <div class="icon1"><img src="/images/openailogo.png" class="settingsicon"/></div>
                              <div class="title">Prompt Transparency</div>
                              <div class="desc mb-4 p-16">
                              Complete transparency in our LLM prompts and findings, as we remain dedicated to our mission. 
                              </div>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div class="container11">
                              <div class="icon1"><img src="/images/pdflogo.png" class="manicon"/></div>
                              <div class="title">Download our Research</div>
                              <div class="desc mb-4 p-16">
                              Open source research as we pursue improved efficiency & accessibility of search engine advertising.
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </AnimatedSection></div>
                                    <div class="mobileonly">
               <div className="container firstsectionre firstsectionremobile">
               <AnimatedSection delay={0.1}>
                  <div className="top-title mb-4 pb-1">RESOURCES</div>
               </AnimatedSection>
                                    <AnimatedSection delay={0.4}>
                  <h1 className="h1 mobiletitle">
                     We present our findings here. Unbiased.
                  </h1>
               </AnimatedSection>
               <AnimatedSection delay={0.5}>
                  <div className="desc mb-4 mt-3 resource-banner-title">
                     Our mission is to improve efficiency and accessibility of search engine advertising.
                  </div>
               </AnimatedSection>
               </div>
         
               <div className=" py-4 threeicons">
                  <div className="cnt2 resource-col-main">
                     <div className="row align-items-center">
                        <div className="col-md-4">
                                    <AnimatedSection>
                           <div class="container11">
                              <div class="icon1 echteicon1"><img src="/images/googlelogo.png" class="speedicon"/></div>
                              <div class="title">Google ads Efficiency</div>
                              <div class="desc mb-4 p-16">
                              Generate your ads and export the data to a Google sheet. From there, simply paste to Google ads.
                              </div>
                           </div>
                                    </AnimatedSection>
                        </div>
                        <div className="col-md-4">
                           <div class="container11">
                              <AnimatedSection><div class="icon1"><img src="/images/openailogo.png" class="settingsicon"/></div></AnimatedSection>
                              <AnimatedSection><div class="title">Prompt Transparency</div></AnimatedSection>
                              <AnimatedSection><div class="desc mb-4 p-16">
                              Complete transparency in our LLM prompts and findings, as we remain dedicated to our mission.
                              </div></AnimatedSection>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div class="container11">
                              <AnimatedSection><div class="icon1"><img src="/images/pdflogo.png" class="manicon"/></div></AnimatedSection>
                              <AnimatedSection><div class="title">Download our Research</div></AnimatedSection>
                              <AnimatedSection><div class="desc mb-4 p-16">
                              Open source research as we pursue improved efficiency & accessibility of search engine advertising.
                              </div></AnimatedSection>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
                        </div>
         </section>

         <AnimatedSection>
            <section className="resources-slider mt-md-5 mt-2 pt-md-5 ">
               <div className="container desktoplarge">
                  <div className=" resource-banner1">
                     <div className="mb-2 d-flex align-items-center justify-content-center">
                        <h2 className="text-white"> Research</h2>
                     </div>
                     <div className="desc mb-4 p-18">
                        Stay ahead in PPC advertising with our latest research,<br />insights and faster ways to launch campaigns.
                     </div>
                     <div className="mobile-image-slider mobileonly">
   <img 
      src={data[activeIndex].image} 
      alt={`Image for ${data[activeIndex].title}`} 
      className="block-image"
   />
</div>
                     <div className=" text-start">
                        <div className="position-relative">
                           <div className="row align-items-center">
                              <div className="col-md-6">
                                 <div className="vertical-slider">
                                    {data.map((block, index) => (
                                       <div
                                          key={index}
                                          className={`vertical-block ${index === activeIndex ? "active" : ""}`}
                                          style={{
                                             cursor: "pointer",
                                          }}
                                          onClick={() => {
                                             clearResearchInterval();
                                             setActiveIndex(index);
                                             startResearchInterval();
                                          }}
                                       >
                                          <h4 class="itemtitle">{block.title}</h4>
                                          <div
                                             className="btm"
                                             style={{
                                                height: index === activeIndex ? heights[index] : 0,
                                                transition: "height 0.5s, opacity 0.5s",
                                                opacity: index === activeIndex ? 1 : 0,
                                                overflow: "hidden",
                                             }}
                                          >
                                             <p class="p-18 itemdesc">{block.description}</p>
                                             <div className="btn-sc d-flex">
                                                <a href={block.url} className="btn btn-sq me-1 thinbtn">
                                                   Download
                                                   <img src="/images/downloadicon.svg" className="ms-2" alt="" />
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                              <div className="col-md-5 rgt">
                                 <div className="vertical-slider image-slider desktoponly">
                                    {data.map((block, index) => (
                                       <div
                                          key={index}
                                          className={`vertical-block ${index === activeIndex ? "active" : ""}`}
                                       >
                                          <img src={block.image} alt="Block Image" className="block-image" />
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </AnimatedSection>
         <AnimatedSection>
            <section className="resources-slider enterprise-block-main mt-5 py-5 ">
               <div className="container desktoplarge">
                  <div className="resource-banner1 agencybanner">
                     <div className="mb-2 d-flex align-items-center justify-content-center">
                        <h2 className="text-white">Agency</h2>
                     </div>
                     <div className="desc mb-4 p-18">
                        Hello agencies and business owners, any questions<br /> about output and prompt engineering?
                     </div>
                                       <div className="mobile-image-slider mobileonly">
   <img 
      src={data2[enterpriseActiveIndex].image} 
      alt={`Image for ${data2[enterpriseActiveIndex].title}`} 
      className="block-image"
   />
</div>
                     <div className=" text-start">
                        <div className="position-relative">
                           <div className="row align-items-center">
                              <div className="col-md-6">
                                 <div className="vertical-slider">
                                    {data2.map((block, index) => (
                                       <div
                                          key={index}
                                          className={`vertical-block ${
                                             index === enterpriseActiveIndex ? "active" : ""
                                          }`}
                                          style={{
                                             cursor: "pointer",
                                          }}
                                          onClick={() => {
                                             clearEnterpriseInterval();
                                             setEnterpriseActiveIndex(index);
                                             startEnterpriseInterval();
                                          }}
                                       >
                                          <h4 class="itemtitle agencytitle">{block.title}</h4>
                                          <div
                                             className="btm"
                                             style={{
                                                height: index === enterpriseActiveIndex ? heights[index] : 0,
                                                transition: "height 0.5s, opacity 0.5s",
                                                opacity: index === enterpriseActiveIndex ? 1 : 0,
                                                overflow: "hidden",
                                             }}
                                          >
                                             <p class="p-18 itemdesc">{block.description}</p>
                                             <div className="btn-sc d-flex">
                                                <a href={block.url} className="btn btn-sq me-1 thinbtn agencybtn">
                                                   Learn more
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                              <div className="col-md-5 rgt">
                                 <div className="vertical-slider image-slider desktoponly">
                                    {data2.map((block, index) => (
                                       <div
                                          key={index}
                                          className={`vertical-block ${
                                             index === enterpriseActiveIndex ? "active" : ""
                                          }`}
                                       >
                                          <img src={block.image} alt="Block Image" className="block-image" />
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </AnimatedSection>
      </div>
   );
};

export default Resource;
