import axios from "axios";
// import { stagger, useAnimate } from "framer-motion";
import React, { useEffect, useState } from "react";

//const staggerMenuItems = stagger(0.1, { startDelay: 0.15 });

function useMoreSettingAnimation(isOpen) {
   const scope = React.useRef(null);
   return scope;
}

// function useMoreSettingAnimation(isOpen) {
   // const [scope, animate] = useAnimate();
   // useEffect(() => {
   // Remove or comment out the animate calls
   // animate(
   //    ".more-setting-btn",
   //    { width: isOpen ? "250px" : "fit-content" },
   //    { type: "keyframes", bounce: 0, duration: 0.5 }
   // );

   // animate(
   //    ".settings-inner",
   //    { opacity: isOpen ? 1 : 0, height: isOpen ? "auto" : 0, y: isOpen ? 0 : 20 },
   //    { type: "keyframes", bounce: 0, duration: 0.5 }
   // );

   // if (isOpen) {
   //    animate(".flex-lb .label-sc", { opacity: 1, y: 0 }, { delay: staggerMenuItems });
   // } else {
   //    animate(".flex-lb .label-sc", { opacity: 0, y: 20 }, { type: "keyframes", duration: 0.3 });
   // }
// }, [isOpen]);

   //return scope;
//}

const MoreSetting = ({handleCheckChange, checkedItems, handleCount, count}) => {
   const [isOpen, setIsOpen] = useState(false);
   const scope = useMoreSettingAnimation(isOpen);
   // const [checkedItems, setCheckedItems] = useState({
   //    all: false,
   //    headline: false,
   //    descriptions: false,
   //    sitelinks: false,
   //    callout: false,
   //    snippets: false,
   //    sheetURL: false,
   // });



   const [warning, setWarning] = useState("");
   // const handleChange = (event) => {
   //    const { name, checked } = event.target;
   //    setCheckedItems({
   //       ...checkedItems,
   //       [name]: checked,
   //    });
   // }

   return (
      <div className={`more-settings py-3`} ref={scope}>
         <div
            className="btn btn-t btn-sq more-setting-btn justify-content-center nohovereffect"
            onClick={() => setIsOpen((prev) => !prev)}
            style={{ minWidth: "fit-content" }}
         >
            More Settings 
         </div>
         <div className="settings-inner overflow-hidden">
            <div className="ross text-end w-100" onClick={() => setIsOpen(false)}>
               X
            </div>
            <div className="outer-label">
               <div className="flex-lb d-flex">
                  <div className="label-sc">
                     <input type="checkbox" name="all" onChange={handleCheckChange} checked={checkedItems.all} />
                     <label htmlFor="">All</label>
                  </div>
                  <div className="label-sc">
                     <input
                        type="checkbox"
                        name="headline"
                        onChange={handleCheckChange}
                        checked={checkedItems.headline}
                     />
                     <label htmlFor="">Headlines</label>
                  </div>
               </div>
               <div className="flex-lb d-flex">
                  <div className="label-sc">
                     <input
                        type="checkbox"
                        name="descriptions"
                        onChange={handleCheckChange}
                        checked={checkedItems.descriptions}
                     />
                     <label htmlFor="descriptions">Descriptions</label>
                  </div>
                  <div className="label-sc">
                     <input
                        type="checkbox"
                        name="sitelinks"
                        checked={checkedItems.sitelinks}
                        onChange={(e) => {
                           // handleChange();
                           handleCheckChange(e);
                        }}
                     />
                     <label htmlFor="sitelinks">Sitelinks</label>
                  </div>
               </div>
               <div className="flex-lb d-flex">
                  <div className="label-sc">
                     <input
                        type="checkbox"
                        name="callout"
                        checked={checkedItems.callout}
                        onChange={(e) => {
                           // handleChange();
                           handleCheckChange(e);
                        }}
                     />
                     <label htmlFor="callout">Callouts</label>
                  </div>
                  <div className="label-sc">
                     <input
                        type="checkbox"
                        name="snippets"
                        checked={checkedItems.snippets}
                        onChange={(e) => {
                           // handleChange();
                           handleCheckChange(e);
                        }}
                     />
                     <label htmlFor="snippets">Snippets</label>
                  </div>
                  {/* <div className="label-sc">
                     <input
                        type="checkbox"
                        name="sheetURL"
                        checked={checkedItems.sheetURL}
                        onChange={handleSheetCheck}
                     />
                     <label htmlFor="sheetURL">SheetURL</label>
                  </div> */}
               </div>
               {checkedItems.sheetURL && (
                  <div className="banner-form-sec mt-4">
                     <div className="form-group me-3">
                        <input
                           placeholder="sheet url"
                           className="form-control"
                           name="sheet_url"
             
                        />
                     </div>
                  </div>
               )}
               <div className=" d-flex amounsc1 mt-2">
                  <div className="label-sc1 d-flex align-items-center">
                     <label htmlFor="" className="me-2">
                        Number of Ads
                     </label>
                     <input
                        placeholder="no."
                        className="form-control"
                        type="text"
                        name="number_of_ads"
                        value={count}
                        onChange={(e)=>{handleCount(e.target.value)}}
                     />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default MoreSetting;
