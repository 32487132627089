import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';

const Testimonial = () => {
    return (
        <div className='testimonial-slider-main'>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={1}
                spaceBetween={0}
                breakpoints={{
                    768: {
                        slidesPerView: 1,
                    },
                    992: {
                        slidesPerView: 1.3,
                    },
                    1024: {
                        slidesPerView: 1.4
                    },
                    1200: {
                        slidesPerView: 1.6
                    }
                }}
                coverflowEffect={{
                    rotate: 0,
                    stretch: -200,
                    depth: 300,
                    modifier: 0.5,
                    slideShadows: false,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                speed={0.8 * 1000}
                pagination={true}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                className="mySwiper"
            >
                {
                    Array.from({ length: 5 }).map((_, i) => (
                        <SwiperSlide key={i}>
                            <div className="testimonial-block text-start">
                                <div className="logo-t">
                                    <img src="/images/logo.png" alt="" />
                                </div>
                                <div className="title testi-title">
                                    “This incredible AI web app has transformed the way I manage data and streamline
                                    processes.”
                                </div>
                                <div className="content m-0 reviewdesc">
                                    Their creativity, attention to detail, and prompt communication set them apart. Our
                                    website looks stunning, and our online campaigns are driving great results.
                                </div>
                                <div className="profile-sc d-flex align-items-center">
                                    <div className="img-sc">
                                        <img src="images/pf.jpg" alt="" />
                                    </div>
                                    <div className="pf-cnt">
                                        <p className="m-0 person-name">Sarah Johnson</p>
                                        <p className="m-0 person-designation">Sarah Johnson</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div >
    )
}

export default Testimonial
