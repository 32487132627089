import React, { useState , useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Style/Login.css";
import AnimatedSection from "../Components/AnimatedSection";
import BackdropAnimation from "../Components/BackdropAnimation";

import { LuArrowLeft } from "react-icons/lu";
import { signIn, signUpWithGoogle, signUpWithMicrosoft } from "../lib/auth";
import { useUser } from "../contexts/userContext";
function Login() {
   const navigate = useNavigate()
   const [email, setEmail] = useState(null);
   const [password, setPassword] = useState(null);
   const { user, setUser } = useUser();

   const handleSubmit = async (e) => {
      e.preventDefault()
      if (email && password) {
         const { error, accessToken, refreshToken } = await signIn({ email, password });
         if (error) {
            alert(error)
            return
         }
         const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/me`, {
            method: 'GET',
            headers: {
               'Authorization': `Bearer ${accessToken}`
            }
         })
         const { user } = await res.json()
        setUser(user);
         navigate('/')
      } else {
         alert('Please fill all required fields')
      }
   }
   useEffect(() => {
  document.title = "Login to PPCC.ai - Access Your Account Here";
  
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", "Access your Account to Launch more Search Ads using AI Today. Instantly Create new PPC Ads for Your Search Ad Campaign. Don't Forget Password.");
  }

  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute("content", "PPC advertising, ai search ads, google ads ai, create search ads");
  }

  // Open Graph tags
  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute("content", "Login to PPCC.ai - Access Your Account Here");
  }

  const ogDescription = document.querySelector('meta[property="og:description"]');
  if (ogDescription) {
    ogDescription.setAttribute("content", "Access your Account to Launch more Search Ads using AI Today. Instantly Create new PPC Ads for Your Search Ad Campaign. Don't Forget Password.");
  }

  const ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute("content", "URL_to_image_for_preview");
  }

  const ogUrl = document.querySelector('meta[property="og:url"]');
  if (ogUrl) {
    ogUrl.setAttribute("content", "https://www.ppcc.ai/login");
  }

  // Twitter Card tags
  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (twitterTitle) {
    twitterTitle.setAttribute("content", "Login to PPCC.ai - Access Your Account Here");
  }

  const twitterDescription = document.querySelector('meta[name="twitter:description"]');
  if (twitterDescription) {
    twitterDescription.setAttribute("content", "Access your Account to Launch more Search Ads using AI Today. Instantly Create new PPC Ads for Your Search Ad Campaign. Don't Forget Password.");
  }

  const twitterImage = document.querySelector('meta[name="twitter:image"]');
  if (twitterImage) {
    twitterImage.setAttribute("content", "URL_to_image_for_twitter");
  }

  const twitterCard = document.querySelector('meta[name="twitter:card"]');
  if (twitterCard) {
    twitterCard.setAttribute("content", "summary_large_image");
  }

}, []);

   return (
      <div>
         <header className="header login-header py-4">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-md-12">
                     <div className="logo">
                        <Link to="/" className="back-to-home-link">
                           <LuArrowLeft className="arrow-left" />
                           <span>Back Home</span>
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </header>
         <AnimatedSection>
            <main className="logincontent">
               <BackdropAnimation
                  style={{
                     top: 0,
                     bottom: "inherit",
                     left: -100,
                     right: "inherit",
                  }}
               />
               <div className="container">
                  <AnimatedSection delay={0}>
                     <h6 className="h6">Welcome back</h6>
                  </AnimatedSection>
                  <AnimatedSection delay={0.1}>
                     <p className="desc title-desc mb-4">Access your account now</p>
                  </AnimatedSection>
                  
                     <div className="banner-form-sec mt-4">
                        <form className="d-flex justify-content-center align-items-center">
                           <div className="form-group d-flex flex-column signin-form-fields">
                              <AnimatedSection delay={0.2}><input type="email" onChange={(e)=>{setEmail(e.target.value)}} placeholder="example@gmail.com" className="form-control" autocapitalize="off" inputmode="email" autocomplete="email"/></AnimatedSection>
                              <AnimatedSection delay={0.3}><input type="password" onChange={(e)=>{setPassword(e.target.value)}} placeholder="Password" className="form-control mt-2" /></AnimatedSection>
                              <AnimatedSection delay={0.4}><button onClick={(e)=>{handleSubmit(e)}} className="form-control mt-3 loginbtn btn" style={{padding:"16px"}}>Sign In</button></AnimatedSection>
                           </div>
                        </form>
                     </div>
                  
                  <AnimatedSection delay={0.5} triggerOnMount>
                     <div className="or-sec-login mb-2 mt-2">OR</div>
                  </AnimatedSection>
                     <div className="banner-form-sec mt-4">
                        <form className="d-flex justify-content-center align-items-center">
                           <div className="form-group ">
                              <AnimatedSection delay={0.6} triggerOnMount><Link onClick={()=>{signUpWithGoogle()}} className="form-control btn btn-border mx-1 mb-3 nohovereffect">
                                 <img src="/images/google.png" alt="" />
                                 Continue With Google
                              </Link></AnimatedSection>
              
                              <AnimatedSection delay={0.7} triggerOnMount><Link onClick={()=>{signUpWithMicrosoft()}} className="form-control btn btn-border mx-1 mb-4 nohovereffect">
                                 <img src="/images/microsoft.png" alt="" />
                                 Continue With Microsoft
                              </Link></AnimatedSection>
                           </div>
                        </form>
                     </div>
                  <AnimatedSection delay={0.2} triggerOnMount>
                     <p className="signup-text mb-3">
                        Don't have an account yet?{" "}
                        <a href="/signup" className="">
                           Create an Account
                        </a>
                     </p>
                  </AnimatedSection>
               </div>
            </main>
         </AnimatedSection>
      </div>
   );
}

export default Login;
