
export async function signUp({ name, email, password }) {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/signUp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name, email, password})
        });
        const data = await response.json();


        if (response.ok) {
            const { accessToken, refreshToken } = data;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            return { error: null, accessToken, refreshToken }
        } else {
            return { error: data.message, accessToken: null, refreshToken: null }
            
        }

    } catch (error) {
        return { error, accessToken: null, refreshToken: null   }
    }
}
export async function signIn({ email, password }) {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email, password})
        });

        const data = await response.json();
        if (response.ok) {
           const { accessToken, refreshToken } = data;
           localStorage.setItem('accessToken', accessToken);
           localStorage.setItem('refreshToken', refreshToken);
           return { accessToken, refreshToken }
        } else {
            return { error: data.message, accessToken: null, refreshToken: null }
            
        }
    } catch (error) {
        console.log(error);
        return { error, accessToken: null, refreshToken: null   }
    }
}

export async function signUpWithMicrosoft() {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/microsoft`

}

export async function signUpWithGoogle() {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google`
}

export async function refresh() {
    try {
        const refreshToken = localStorage.getItem('refreshToken');

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/refresh`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${refreshToken}`
            }
        });

        const data = await response.json()
        if (response.ok) {
            const { accessToken } = data;
            localStorage.setItem('accessToken', accessToken);
            return { accessToken } 
        } else {
            localStorage.clear()
            window.location.reload()
        }
    } catch (error) {
        console.log(error);
        localStorage.clear()

    }

}

export async function AuthFetch(url, options) {
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${url}`, {
        ...options,
        headers: {
            ...options.headers,
            'Authorization': `Bearer ${accessToken}`
        }
    });

    if (response.status === 401) {
        const { accessToken: newAccessToken } = await refresh();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${url}`, {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': `Bearer ${newAccessToken}`
            }
        });
        const data = await response.json();
        return data
    } else {
        const data = await response.json();
        return data
    }
}

export async function signOut() {
    localStorage.clear();
}

export async function AuthFetch2(url, options) {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${url}`, {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': `Bearer ${accessToken}`
            }
        });
    
        if (response.status === 401) {
            const { accessToken: newAccessToken } = await refresh();
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${url}`, {
                ...options,
                headers: {
                    ...options.headers,
                    'Authorization': `Bearer ${newAccessToken}`
                }
            });
           return response
        } 
    
        return response
    } catch (error) {
        console.log(error)
    }

}