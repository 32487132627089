import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";

const HeightAnimatedSection = ({ children, delay }) => {
   const [height, setHeight] = useState("auto");
   const ref = useRef(null);

   useEffect(() => {
      const updateHeight = () => {
         if (ref.current) {
            setHeight(ref.current.scrollHeight);
         }
      };

      updateHeight(); // Initial height calculation

      // Add resize event listener to recalculate height on screen resize
      window.addEventListener("resize", updateHeight);

      // Cleanup the event listener on component unmount
      return () => window.removeEventListener("resize", updateHeight);
   }, [children]);

   return (
      <div style={{ height: "auto", overflow: "visible" }}> {/* Ensure no clipping */}
         <motion.div
            initial={{ height: 0, opacity: 0, y: 150 }}
            animate={{ height: height, opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut", delay: delay || 0 }}
            style={{ overflow: "visible" }} /* Changed to visible to ensure no hidden content */
            ref={ref}
         >
            {children}
         </motion.div>
      </div>
   );
};

export default HeightAnimatedSection;
