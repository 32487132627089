import React from "react";
import AnimatedSection from "./AnimatedSection";

const accordionData = [
   {
      title: "What does PPCC.AI do?",
      description:
         "PPCC.AI simplifies the process of creating and launching Google Search Ads. Leveraging AI, we help businesses and agencies quickly generate ads by simply entering their domain. ",
   },
   {
      title: "Can I use PPCC.AI for Free?",
      description:
         "Yes, anyone can generate an ad with PPCC.AI and register for an account to extract the data. Registration is free and you start with 1000 credits.",
   },
   {
      title: "What does the extraction look like?",
      description: `When extracting Campaign data, you will receive an email with an Excel file attached which you can copy to a Google sheet. The Google sheet is structured like this, and highlights character limits.`,
   },
   {
      title: "How do I launch a campaign?",
      description: `Once you have extracted your ad data, simply copy paste the rows and columns to the Google Ads Editor, make some final changes, and post! If it's your first time with us, you will receive a step-by-step guide and a short tutorial video.`,
   },
   {
      title: "What about strategy and optimisation?",
      description: `Leveraging Google's built-in AI optimisation tools, little to no optimisation is required for limited keywords / brandname campaigns. Broad campaigns will require for more manual optimisation and understanding of strategy.`,
   },
   {
      title: "What are credits?",
      description: `Our pay-as-you-go credit system gives you flexible access to ad creation features. Use credits to generate custom ad campaigns and refine elements like headlines and snippets. The exact credits needed are displayed before you download.`,
   },
];

const FAQSec = () => {
   return (
      <AnimatedSection>
         <section className="faq-sc text-center space-mr-m mb-5">
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-md-7">
                     <div className="h2 subtitlepricing titleminmargintop">Frequently asked questions</div>
                     <AnimatedSection>
                        <p class="p-18 faqp">Everything you need to know about the product and billing.</p>
                     </AnimatedSection>
                     <AnimatedSection>
                        <div class="accordion accordion-flush text-start mt-5" id="accordionFlushExample">
                           {accordionData?.map((item, index) => {
                              return (
                                 <div class="accordion-item">
                                    <h2 class="accordion-header" id={"faq_heading" + index}>
                                       <button
                                          class="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={"#faq" + index}
                                          aria-expanded="false"
                                          aria-controls={"faq" + index}
                                       >
                                          {item.title}
                                       </button>
                                    </h2>
                                    <div
                                       id={"faq" + index}
                                       class="accordion-collapse collapse"
                                       aria-labelledby={"faq" + index}
                                       data-bs-parent="#accordionFlushExample"
                                    >
                                       <div class="accordion-body">{item.description}</div>
                                    </div>
                                 </div>
                              );
                           })}
                        </div>
                     </AnimatedSection>
                  </div>
               </div>
            </div>
         </section>
      </AnimatedSection>
   );
};

export default FAQSec;
