import React, { useState } from "react";
import { TbRosetteDiscountCheckFilled } from "react-icons/tb";
import { motion } from "framer-motion";
import { AuthFetch } from "../lib/auth";
import useAuth from "../hooks/useAuth";
import { useNavigate } from 'react-router-dom';

const daysLeft = () => {
   const today = new Date();
   const jan1 = new Date(today.getFullYear() + 1, 0, 1); // January 1 of the next year
   const diffInTime = jan1 - today; // Difference in milliseconds
   return Math.ceil(diffInTime / (1000 * 60 * 60 * 24)); // Convert to days
};
const PricingTableMobile = () => {
   const [loading, setLoading] = useState(false);
   const isAuthenticated = useAuth();
   const navigate = useNavigate();
   const daysLeftUntilJan = daysLeft();

   const createCheckoutSession = async (priceId) => {
      setLoading(true);
      if (!isAuthenticated) {
         navigate('/login');
         return;
      }
      
      const { session } = await AuthFetch(`stripe/checkoutSession/${priceId}`, {
         method: 'GET',
      });
      window.location.href = session;
   };

   const animatedCornerProps = {
      initial: { left: "-10%", opacity: 1 },
      animate: { left: ["-10%", "90%"], opacity: [1, 1, 0] },
      transition: {
         duration: 4,
         ease: [0.2, 0.4, 0.8, 1],
         repeat: Infinity,
         times: [0, 0.7, 1],
      },
   };

   return (
      <div className="pricing-mob-main">
         <div className="pricing-table-title-block">
            <div className="title">
               <h5>No Monthly Subscriptions</h5>
               <div className="percentage">{daysLeftUntilJan} days left</div>
            </div>
            <p className="title-para p-16">Enjoy our pay as you go model while we celebrate our launch.</p>
         </div>

         {!isAuthenticated && (  
            <div className="plan-card-main">
            <motion.div
      className="gradient d-block"
      initial={{ left: "0%", opacity: 0 }}
      animate={{ left: ["0%", "70%"], opacity: [1, 0.5, 0.1] }}
      transition={{
         duration: 2.3,
         ease: [0.2, 0.4, 0.8, 1],
         repeat: Infinity,
         repeatDelay: 0.7,
         times: [0, 0.7, 1],
      }}
      style={{
         background: "linear-gradient(270deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 88.2461%)",
         height: "1px",
         position: "absolute",
         top: "-1px",
         width: "40px",
         zIndex: 1
      }}
   />
   <motion.div
      className="gradient-bottom d-block"
      initial={{ top: "0%", opacity: 0 }}
      animate={{ top: ["0%", "70%"], opacity: [1, 0.5, 0.1] }}
      transition={{
         duration: 2.3,
         ease: [0.2, 0.4, 0.8, 1],
         repeat: Infinity,
         repeatDelay: 0.7,
         times: [0, 0.7, 1],
      }}
      style={{
         background: "linear-gradient(360deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 88.2461%)",
         height: "40px",
         position: "absolute",
         right: "-1px",
         top: "-4px",
         width: "1px",
         zIndex: 1
      }}
   />
               <div className="card-title-main">
                  <div className="card-price-main">
                     <h4>Free</h4>
                     <span>/Month</span>
                  </div>
                  <button onClick={()=>{createCheckoutSession(process.env.REACT_APP_PRICE_ID_2500)}} className="choose-plan-btn">Create an Account</button>
               </div>
               <div className="price-table-row">
                  <h6>Starting balance</h6>
                  <p>1,000 credits</p>
               </div>
               <div className="price-table-row">
                  <h6>Equivalent of</h6>
                  <p>2 campaigns</p>
               </div>
            </div>
         )}

         <div className="plan-card-main">
            <div className="card-title-main">
               <div className="card-price-main">
                  <h4>$0.02</h4>
                  <span>/Credit</span>
               </div>
               <button onClick={()=>{createCheckoutSession(process.env.REACT_APP_PRICE_ID_2500)}} className="choose-plan-btn">Purchase for $19</button>
            </div>
            <div className="price-table-row">
               <h6>Amount of credits</h6>
               <p>2,500 credits</p>
            </div>
            <div className="price-table-row">
               <h6>Equivalent of</h6>
               <p>5 campaigns</p>
            </div>
            <div className="price-table-row">
               <div className="check-list d-flex align-items-center flex-row h-100 gap-3">
                  <TbRosetteDiscountCheckFilled color="#fff" size={20} />
                  <p>Extract all campaign data</p>
               </div>
            </div>
            <div className="price-table-row">
               <div className="check-list d-flex align-items-center flex-row h-100 gap-3">
                  <TbRosetteDiscountCheckFilled color="#fff" size={20} />
                  <p>Multiple editions to publish</p>
               </div>
            </div>
         </div>

         <div className="plan-card-main secondmain">
           <motion.div
      className="gradient d-block"
      initial={{ left: "0%", opacity: 0 }}
      animate={{ left: ["0%", "70%"], opacity: [1, 0.5, 0.1] }}
      transition={{
         duration: 2.3,
         ease: [0.2, 0.4, 0.8, 1],
         repeat: Infinity,
         repeatDelay: 0.7,
         times: [0, 0.7, 1],
      }}
      style={{
         background: "linear-gradient(270deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 88.2461%)",
         height: "1px",
         position: "absolute",
         top: "-1px",
         width: "40px",
         zIndex: 1
      }}
   />
   <motion.div
      className="gradient-bottom d-block"
      initial={{ top: "0%", opacity: 0 }}
      animate={{ top: ["0%", "70%"], opacity: [1, 0.5, 0.1] }}
      transition={{
         duration: 2.3,
         ease: [0.2, 0.4, 0.8, 1],
         repeat: Infinity,
         repeatDelay: 0.7,
         times: [0, 0.7, 1],
      }}
      style={{
         background: "linear-gradient(360deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 88.2461%)",
         height: "40px",
         position: "absolute",
         right: "-1px",
         top: "-4px",
         width: "1px",
         zIndex: 1
      }}
   />
            <div className="card-title-main">
               <div className="card-price-main">
                  <h4>$0.015</h4>
                  <span>/Credit</span>
               </div>
               <button onClick={()=>{createCheckoutSession(process.env.REACT_APP_PRICE_ID_15000)}} className="choose-plan-btn">Purchase for $49</button>
            </div>
            <div className="price-table-row">
               <h6>Amount of credits</h6>
               <p>15,000 credits</p>
            </div>
            <div className="price-table-row">
               <h6>Equivalent of</h6>
               <p>30 campaigns</p>
            </div>
            <div className="price-table-row">
               <div className="check-list d-flex align-items-center flex-row h-100 gap-3">
                  <TbRosetteDiscountCheckFilled color="#fff" size={20} />
                  <p>Extract all campaign data</p>
               </div>
            </div>
            <div className="price-table-row">
               <div className="check-list d-flex align-items-center flex-row h-100 gap-3">
                  <TbRosetteDiscountCheckFilled color="#fff" size={20} />
                  <p>Multiple editions to publish</p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default PricingTableMobile;
