import { Children, createContext, useContext, useEffect, useState } from "react";
import { AuthFetch } from "../lib/auth";

const UserContext = createContext();

export function UserProvider({ children }) {
    const [user, setUser] = useState(null);
    const fetchUser = async () => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            return
        }

        const { user } = await AuthFetch('user/me', {
            method: 'GET'
        });
        setUser(user)
    }
    useEffect(()=>{
        fetchUser()
    }, [])

    return (
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => {
    return useContext(UserContext)
}