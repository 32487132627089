import axios from "axios";
import { motion, useSpring, useTransform, useViewportScroll } from "framer-motion";
import React, { useState , useEffect , useRef } from "react";
import AnimatedSection from "../Components/AnimatedSection";
// import { Modal, Button } from 'react-bootstrap';
import Loader from "../Components/Loader";
import BackdropAnimation from "../Components/BackdropAnimation";
import HeightAnimatedSection from "../Components/HeightAnimatedSection";
import MoreSetting from "../Components/MoreSetting";
import BackdropGlow from "../Components/BackdropGlow";
import useAuth from "../hooks/useAuth";
import { AuthFetch, AuthFetch2, refresh, signUpWithGoogle, signUpWithMicrosoft } from "../lib/auth";
import { useNavigate } from "react-router-dom";
import { stagger, useAnimate } from "framer-motion";

function Homepage() {
   const isAuthenticated = useAuth();
   const navigate = useNavigate()
   const Mocksite = [
      {
         site_text: "Try for Yourself",
         site_url:
            "#",
         sitePara: "Generate a PPC ad for your website now.",
      },
      {
         site_text: "It's Completely Free",
         site_url:
            "#",
         sitePara: "See how AI makes ad creation effortless.",
      },
      {
         site_text: "Your Page here",
         site_url:
            "#",
         sitePara: "Let's customize your journey with us.",
      },
      {
         site_text: "Launch in 5 Minutes",
         site_url:
            "#",
         sitePara: "Try for yourself by entering a URL above.",
      },
   ];
   const { scrollY } = useViewportScroll();
   const scale = useTransform(scrollY, [0, 1000], [1, 0.9]);
   const smoothScale = useSpring(scale, { stiffness: 50, damping: 10 });
   const [showModal, setShowModal] = useState(false);
   const [showModalNext, setShowModalNext] = useState(false);
   const [isActive, setIsActive] = useState(false);
   const [siteData, setSiteData] = useState();
   const [sheetData, setSheetData] = useState();
   const [warning, setWarning] = useState("");
   const [generateSite, setGenerateSite] = useState({
      site_description: "",
      site_url: "",
   });
   const [generateSheet, setGenerateSheet] = useState({
      sheet_url: "",
      number_of_ads: "",
   });
   const [isLoading, setIsLoading] = useState(false);
   const handleToggle = () => {
      setIsActive(!isActive);
   };
   const handleShow = () => setShowModal(true);
   const handleClose = () => setShowModal(false);

   const [checkedItems, setCheckedItems] = useState({
      all: true,
      headline: true,
      descriptions: true,
      sitelinks: true,
      callout: true,
      snippets: true,
      sheetURL: true,
   });
   const [numberOfAds, setNumberOfAds] = useState(1);
   const [totalCost, setTotalCost] = useState(250);
   const staggerMenuItems = stagger(0.1, { startDelay: 0.15 });


   const [url, setUrl] = useState(null);
   const [mockup, setMockup] = useState({
   url: "https://www.brandname.com",
   headline: "Your Brandname - Enter Your URL Above",
   description: "Enter a Website and instantly Generate PPC search ads. Experience how Easy it is to Launch Ads with AI—no expertise requi",
   display_url: "yourwebsite.com",
   sitelinks: [
      { title: "Try for Yourself", description: "Generate a PPC ad for your website now." },
      { title: "It's Completely Free", description: "See how AI makes ad creation effortless." },
      { title: "Your Page here", description: "Let's customize your journey with us." },
      { title: "Launch in 5 Min", description: "Try for yourself by entering a URL above." },
   ],
});
   const [mockupLoading, setMockupLoading] = useState(false);
   const [extractLoading, setExtractLoading] = useState(false);
   const COSTS = {
    headline: 50,
    descriptions: 50,
    sitelinks: 50,
    callout: 50,
    snippets: 50,
};
const calculateTotalCost = () => {
   const fields = ['headline', 'descriptions', 'sitelinks', 'callout', 'snippets'];
   let cost = 0;

   // Safely iterate through fields and add their cost
   fields.forEach((field) => {
       if (checkedItems[field]) {
           cost += COSTS[field];
       }
   });

   // Safely parse `numberOfAds` or default to 1
   const adsCount = numberOfAds > 0 ? numberOfAds : 1;

   // Final cost calculation
   setTotalCost(cost * adsCount);
};




useEffect(() => {
   calculateTotalCost(); 
}, []);

useEffect(() => {
   calculateTotalCost(); 
}, [numberOfAds, checkedItems]);





   const generateMockup = async () => {
      if (url) {
               const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
         try {
            setMockupLoading(true)
           
               const response = await AuthFetch2('campaign-gen/mockupGen', {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json'
               },
               body: JSON.stringify({
                  url: formattedUrl
               })
            });
            
   
            const data = await response.json()
            if (data?.message) {
               setMockupLoading(false)
               return alert(data?.message)
            }
            
            setMockup(data?.mockup);
            setMockupLoading(false)

            //scroll to mockup gpt
            if (mockupRef.current) {
   mockupRef.current.scrollIntoView({ behavior: 'smooth' });
}
         } catch (error) {
            console.log(error)
         }

      } else {
         alert('Please fill valid url')
      }

   }

   const [isOpen, setIsOpen] = useState(false);
   const [scope, animate] = useAnimate();
   
const mockupRef = useRef(null); //gpt scroll to mockup
   const [dropdownPadding, setDropdownPadding] = useState(0); // Padding for footer spacing

   
   const handleCheckChange = (e) => {
      const { name, checked } = e.target;
  
      if (name === "all") {
          // Toggle all options
          const newCheckedItems = {
              all: checked,
              headline: checked,
              descriptions: checked,
              sitelinks: checked,
              callout: checked,
              snippets: checked,
          };
          setCheckedItems(newCheckedItems);
      } else {
          // Toggle individual option
          setCheckedItems((prev) => {
              const newCheckedItems = { ...prev, [name]: checked };
  
              // Update "all" if all other checkboxes are selected
              const allSelected = Object.keys(newCheckedItems)
                  .filter((key) => key !== "all")
                  .every((key) => newCheckedItems[key]);
  
              newCheckedItems.all = allSelected;
  
              return newCheckedItems;
          });
      }
  
      calculateTotalCost();
  };
  



   const formatUrl = (url) => {
   // Remove protocol (https:// or http://)
   let formattedUrl = url.replace(/(^\w+:|^)\/\//, '');

   // Remove 'www.' if it exists
   formattedUrl = formattedUrl.replace(/^www\./, '');

   // Remove everything after the first '.'
   formattedUrl = formattedUrl.split('.')[0];

   return formattedUrl;
};
   
   const handleExtract = async () => {
      if (!isAuthenticated) return navigate('/login');
      if (!url) return alert('Please fill valid url');
      const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

      setExtractLoading(true);
      const accessToken = localStorage.getItem('accessToken')
      const { headline, descriptions, sitelinks, callout, snippets } = checkedItems
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
         },
         body: JSON.stringify({
            url: formattedUrl, headline, descriptions, sitelinks, callout, snippets, count: numberOfAds
         })
      });

      if (response.ok) {
         const blob = await response.blob();
         const url = window.URL.createObjectURL(blob);

         // Create an anchor element and trigger a download
         const a = document.createElement('a');
         a.href = url;
         a.download = 'campaign_template.xlsx'; // Set the filename for download
         document.body.appendChild(a);
         a.click();

         // Cleanup: Remove the link element and revoke the blob URL
         a.remove();
         window.URL.revokeObjectURL(url);
         setExtractLoading(false)

      } else if (response.status === 401) {
         const { accessToken: newAccessToken } = await refresh();
         const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${newAccessToken}`
            },
            body: JSON.stringify({
               url, headline, descriptions, sitelinks, callout, snippets
            })
         });

         if (res.ok) {
            const blob = await res.blob();
            const url = window.URL.createObjectURL(blob);

            // Create an anchor element and trigger a download
            const a = document.createElement('a');
            a.href = url;
            a.download = 'campaign_template.xlsx'; // Set the filename for download
            document.body.appendChild(a);
            a.click();

            // Cleanup: Remove the link element and revoke the blob URL
            a.remove();
            window.URL.revokeObjectURL(url);
            setExtractLoading(false)
         } else {
            const data = await response.json();
            alert(data.message);
            setExtractLoading(false)
         }
      } else {
         const data = await response.json();
         alert(data?.message)
         setExtractLoading(false)
      }

   }
   

   useEffect(() => {
  document.title = "Instant Google Ads Generator - Launch PPC ads in Seconds";
  
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", "Generate PPC ads instantly using AI. Enter your website to Create, Export and Launch Ads to All Search Engines. Simple, Fast, and Good-Looking.");
  }

  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute("content", "PPC advertising, ai search ads, google ads ai, create search ads");
  }

  // Open Graph tags
  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute("content", "Instant Google Ads Generator - Launch PPC ads in Seconds");
  }

  const ogDescription = document.querySelector('meta[property="og:description"]');
  if (ogDescription) {
    ogDescription.setAttribute("content", "Generate PPC ads instantly using AI. Enter your website to Create, Export and Launch Ads to All Search Engines. Simple, Fast, and Good-Looking.");
  }

  const ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute("content", "URL_to_image_for_preview");
  }

  const ogUrl = document.querySelector('meta[property="og:url"]');
  if (ogUrl) {
    ogUrl.setAttribute("content", "https://www.ppcc.ai");
  }

  // Twitter Card tags
  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (twitterTitle) {
    twitterTitle.setAttribute("content", "Instant Google Ads Generator - Launch PPC ads in Seconds");
  }

  const twitterDescription = document.querySelector('meta[name="twitter:description"]');
  if (twitterDescription) {
    twitterDescription.setAttribute("content", "Generate PPC ads instantly using AI. Enter your website to Create, Export and Launch Ads to All Search Engines. Simple, Fast, and Good-Looking.");
  }

  const twitterImage = document.querySelector('meta[name="twitter:image"]');
  if (twitterImage) {
    twitterImage.setAttribute("content", "URL_to_image_for_twitter");
  }

  const twitterCard = document.querySelector('meta[name="twitter:card"]');
  if (twitterCard) {
    twitterCard.setAttribute("content", "summary_large_image");
  }

}, []);

// Animation-related useEffect
useEffect(() => {
   if (isOpen) {
      const labels = document.querySelectorAll(".label-sc");
      labels.forEach((label, index) => {
         animate(
            label,
            { opacity: 1, y: 0 },
            { delay: index * 0.1 } // Slightly staggers each label by 0.1s
         );
      });
   } else {
      const labels = document.querySelectorAll(".label-sc");
      labels.forEach((label) => {
         animate(
            label,
            { opacity: 0, y: 20 },
            { duration: 0.3 }
         );
      });
   }
}, [isOpen, animate]);
   


   return (
      <>
         <Loader isLoading={mockupLoading || extractLoading} />
         <div className="main">
            <div className="outer-banner position-relative">
               <section className="banner text-center banner-home">
                  <div className="d-md-block d-none">
                     <BackdropAnimation delay={1.5}
   
   />
                  </div>
                  <div className="d-md-none d-block">
                     <BackdropGlow />
                  </div>
   
<div className="container mobileonly">
<AnimatedSection>
<div className="top-title mb-md-5 pb-1 mb-2">CREATE A CAMPAIGN</div>
</AnimatedSection>
<AnimatedSection delay={0.2}>
<h1 className="h1 h1mobile">The Fastest Way to Launch Search Ads</h1>
</AnimatedSection>
<AnimatedSection delay={0.3}>
<div className="desc mb-4">
<p className="p-18 generatep">Using AI to improve cost-efficiency and accessibility of PPC advertising. Enter your website url below.
</p>
</div>
</AnimatedSection>
                              <HeightAnimatedSection delay={0.4}>
                              <div className="banner-form-sec mt-md-5 mobilehomesec">
                                 <form className="d-md-flex justify-content-center align-items-center generateform" onSubmit={(e) => {
      e.preventDefault();  // Prevent form from reloading the page
      generateMockup();    // Trigger the button action
   }}>                             
   <div className="form-group me-0 me-md-3 ">
                                       <input
                                          placeholder="https://www.yourwebsite.com"
                                          className="form-control"
                                          name="site_url"
                                          value={url}
                                          onChange={(e)=>{setUrl(e.target.value)}}
                                             autocomplete="url"

                                       />
                                    </div>
                                    <div className="submit-btn mt-md-0 mt-3 ">
                                       <button type="button" className="btn " onClick={generateMockup}>
                                          Generate ad
                                          <svg
                                             xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 256 256"
                                             focusable="false"
                                             color="rgb(255, 255, 255)"
                                          >
                                             <g color="rgb(255, 255, 255)" weight="bold">
                                                <path d="M224.49,136.49l-72,72a12,12,0,0,1-17-17L187,140H40a12,12,0,0,1,0-24H187L135.51,64.48a12,12,0,0,1,17-17l72,72A12,12,0,0,1,224.49,136.49Z"></path>
                                             </g>
                                          </svg>
                                       </button>
                                    </div>
                                 </form>
                              </div>
                                                {isAuthenticated && (
    <div className="signedinundergenerate"></div>
    )}                                                
                                                </HeightAnimatedSection>

</div>
                                                

   
                        <div className="container desktoponly">
                           <motion.div style={{ scale: smoothScale, overflow: "visible", position: "relative" }}>
  <div style={{ overflow: "visible", position: "relative" }}> {/* Additional layer to isolate scaling */}
                                                   <AnimatedSection delay={0.2}>
                              <div className="top-title mb-md-5 pb-1 mb-2">CREATE A CAMPAIGN</div>
                           </AnimatedSection>
                           <AnimatedSection delay={0.4}>
                              <h1 className="h1">The Fastest Way to Launch Search Ads</h1>
                           </AnimatedSection>
                           <AnimatedSection delay={0.6}>
                              <div className="desc mb-4">
                                 <p class="p-18 generatep">Using AI to improve cost-efficiency and accessibility of PPC advertising. Enter your website url below.</p>
                              </div>
                           </AnimatedSection>
                           <HeightAnimatedSection delay={0.9}>
                              <div className="banner-form-sec mt-md-5">
                                 <form className="d-md-flex justify-content-center align-items-center generateform" onSubmit={(e) => {
         e.preventDefault();  // Prevent form from reloading the page
         generateMockup();    // Trigger the button action
      }}>
                                    <div className="form-group me-0 me-md-3 ">
                                       <input
                                          placeholder="https://www.yourwebsite.com"
                                          className="form-control"
                                          name="site_url"
                                          value={url}
                                          onChange={(e)=>{setUrl(e.target.value)}}
                                       />
                                    </div>

                                    <div className="submit-btn mt-md-0 mt-3 ">
                                       <button type="button" className="btn " onClick={generateMockup}>
                                          Generate ad
                                          <svg
                                             xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 256 256"
                                             focusable="false"
                                             color="rgb(255, 255, 255)"
                                          >
                                             <g color="rgb(255, 255, 255)" weight="bold">
                                                <path d="M224.49,136.49l-72,72a12,12,0,0,1-17-17L187,140H40a12,12,0,0,1,0-24H187L135.51,64.48a12,12,0,0,1,17-17l72,72A12,12,0,0,1,224.49,136.49Z"></path>
                                             </g>
                                          </svg>
                                       </button>
                                    </div>
                                 </form>
                                 <div className="modal-body"></div>
                              </div>
                           </HeightAnimatedSection>
                                                </div>
                                                </motion.div>
                                                 <motion.div style={{ scale: smoothScale }}>
  <div className="d-md-block d-none pb-3">
    {!isAuthenticated && (
        <>
          <AnimatedSection delay={0.8}>
            <div className="or-sec mt-5">OR</div>
          </AnimatedSection>
          <AnimatedSection delay={0.7}>
            <div className="signup-text">
              Sign up for free with Google or Microsoft.
            </div>
          </AnimatedSection>
          <AnimatedSection delay={0.8}>
            <div
              className="btm-btn-sc d-sm-flex justify-content-center mx-auto btnswith"
              style={{
                width: "fit-content",
              }}
            >
              <button
                onClick={() => {
                  signUpWithGoogle();
                }}
                className="btn btn-border mx-1 g-f-button nohovereffect"
              >
                <img src="/images/google.png" alt="" />
                Continue With Google
              </button>
              <button
                onClick={() => {
                  signUpWithMicrosoft();
                }}
                className="btn btn-border mx-1 g-f-button nohovereffect"
              >
                <img src="/images/microsoft.png" alt="" />
                Continue With Microsoft
              </button>
            </div>
          </AnimatedSection>
        </>
)}
{isAuthenticated && (
    <div className="signedinundergenerate"></div>
    )}
  </div>
</motion.div>
       </div>
       </section>

               
               {mockup !== null && (
                  <section ref={mockupRef} className="frame-image-sc space-mr">
                     {/* First Section  */}
                     <AnimatedSection>
                        <div className="container">
                           <div className="wrapper">
                              <div className="desktop_mockup nomobile">
                                 <div className="google_interface google_interface_desktop">
                                    <div className="header_area">
                                       <div className="header_ser">
                                          <div className="serach_wrapper">
                                             <div className="google_logo">
                                                <img src="images/Google_2015_logo2.png" alt="" height="40px" class="googlelogoimg"/>
                                             </div>
                                             <div className="google_serach">
                                                <div className="ser">
                                                   <input
                                                      type="text"
                                                      className="google_serach_input p-0"
                                                      value={mockup.url ? formatUrl(mockup.url) : "brandname"}
                                                   />
                                                   <div className="google-search-close me-2">
                                                      <svg
                                                         focusable="false"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 24 24"
                                                      >
                                                         <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                                      </svg>
                                                   </div>
                                                </div>
                                                <div className="end_area">
                                                   <button href="#"className="goog_btn mic">
                                                      <img src="images/Google_mic.svg" alt="" />
                                                   </button>
                                                   <button href="#"className=" goog_btn lens">
                                                      <img src="images/7123028_lens_google_icon.svg" alt="" />
                                                   </button>
                                                   <button href="#"className="goog_btn serch_ico">
                                                      <img src="images/Vector_search_icon.svg.png" alt="" />
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="right ">
                                             {/* <div className="search_labs">
                                             <img src="images/serachlabs.png" alt="" width="25px" />
                                          </div> */}
                                             <div className="google_apps">
                                                <img src="images/186401_grid_icon.png" alt="" width="25px" />
                                             </div>
                                             <div className="gmail">
                                                <img src="images/smalllogo.png" alt="" />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="tabs">
                                          <div className="left_tabs">
                                             <button href="#"className="tab_btn active">All</button>
                                             <button href="#"className="tab_btn">Images</button>
                                             <button href="#"className="tab_btn">Videos</button>
                                             <button href="#"className="tab_btn">News</button>
                                             <button href="#"className="tab_btn">Shopping</button>
                                             <button href="#"className="tab_btn">Maps</button>
                                             <button href="#"className="tab_btn">Books</button>
                                             <button href="#"className="tab_btn d-none">
                                                <span>
                                                   <i className="bx bx-dots-vertical-rounded"></i>
                                                </span>
                                                More
                                             </button>
                                          </div>
                                          <div className="right_tabs d-none">
                                             <button className="tab_btn ">Tool</button>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="capsul_tab">
                                       <button className="capsul">Free</button>
                                       <button className="capsul">Reviews</button>
                                       <button className="capsul">Open Now</button>
                                       <button className="capsul">Clips</button>
                                       <button className="capsul">Pricing</button>
                                       <button className="capsul">Alternative</button>
                                    </div>
                                    <div className="google_serach_result">
                                       <div className="sponsored-text2">
                                          <span>Sponsored</span>
                                       </div>
                                       <div className="serach_item">
                                          <div className="brand_logo_img">
                                             <img src="images/smalllogo.png" alt="" />
                                          </div>
                                          <div className="brand_info">
                                             <div className="barnd_name">
                                                <h6 className="m-0 ">
                                                   {mockup?.display_url
                                                      ? mockup?.display_url
                                                      : "ppcc.ai"}
                                                </h6>
                                                <div className="link">
                                                   <p className="website_link">
                                                      {mockup?.url
                                                         ? mockup?.url
                                                         : "# "}
                                                   </p>
                                                   <a href="#" className="modal_dot">
                                                      <i className="bx bx-dots-vertical-rounded"></i>
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="serach_result_main">
                                          <a
                                             href="#"
                                             className=""
                                             
                                                onclick="return false;"
                                          >
                                             <h1>
                                                {mockup?.headline
                                                   ? mockup?.headline
                                                   : " PPCC - Generate Google Ads - Try for Free "}
                                             </h1>
                                          </a>
                                          {/* <span className="brand_name_highlisght">Munch</span> */}
                                          <p className="info">
                                             {mockup?.description
                                                ? mockup?.description
                                                : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                                          </p>
                                       </div>
                                       {mockup?.sitelinks?.length >= 0
                                          ? mockup?.sitelinks?.map((item, index) => (
                                             <div className="Ktlw8e" key={index}>
                                             <div className="iCzAIb">
                                                <div className="d-flx mock-site-link">
                                                   <a href="#" className="" onclick="return false;">{item.title}</a>
                                                   <div className="icon d-none-icon">
                                                      <i className="bx bx-chevron-right"></i>
                                                   </div>
                                                </div>
                                                <p className="info m-0">{item.description}</p>
                                             </div>
                                          </div>
                                            ))
                                          : Mocksite?.map((item, index) => (
                                               <div className="Ktlw8e" key={index}>
                                                  <div className="iCzAIb">
                                                     <div className="d-flx mock-site-link">
                                                        <a className="" onclick="return false;">{item.site_text}</a>
                                                        <div className="icon d-none-icon">
                                                           <i className="bx bx-chevron-right"></i>
                                                        </div>
                                                     </div>
                                                     <p className="info m-0">{item.sitePara}...</p>
                                                  </div>
                                               </div>
                                            ))}


{/* SECOND SEARCH RESULT DESKTOP */}
                                               <div className="serach_item serach_item2">
                                          <div className="brand_logo_img">
                                             <img src="images/smalllogo.png" alt="" />
                                          </div>
                                          <div className="brand_info">
                                             <div className="barnd_name">
                                                <h6 className="m-0 ">
                                                   {mockup?.display_url
                                                      ? mockup?.display_url
                                                      : "ppcc.ai"}
                                                </h6>
                                                <div className="link">
                                                   <p className="website_link">
                                                      {mockup?.url
                                                         ? mockup?.url
                                                         : "#"}
                                                   </p>
                                                   <a href="#" className="modal_dot">
                                                      <i className="bx bx-dots-vertical-rounded"></i>
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="serach_result_main">
                                          <a
                                             href="https://www.ppcc.ai/#"
                                             className=""
                                            
                                                onclick="return false;"
                                          >
                                             <h1>
                                                {mockup?.headline
                                                   ? mockup?.headline
                                                   : " PPCC - Generate Google Ads - Try for Free "}
                                             </h1>
                                          </a>
                                          {/* <span className="brand_name_highlisght">Munch</span> */}
                                          <p className="info">
                                             {mockup?.description
                                                ? mockup?.description
                                                : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                                          </p>
                                             <div class="snippetdiv"><a href="#"><span class="snippet">Your</span></a><a href="#"><span class="snippet">Callouts</span></a><a href="#"><span class="snippet">Here</span></a><a href="#"><span class="snippet">Try for Free</span></a></div>
   

                                               
                                    </div>
                                       
                                    </div>
                                 </div>
                              </div>
                                             <div className="desktop_mockup mobile_mockup">
                                 <div className="google_interface google_interface_mobile">
                                    <div className="header_area_mobile">
                                       <div className="header_ser_mobile">
                                          <div className="serach_wapper">
                                             <div className="google_logo_mobile">
                                                <img src="images/Google_2015_logo2.png" alt="" height="40px" />
                                             </div>
   <div className="gmail gmailmobile">
                                                <img src="images/smalllogo.png" alt="" />
                                             </div>
                                             <div className="google_serach google_serach_mobile">
                                                <div className="ser">
                                                   
                                                   <button href="#"className="goog_btn serch_ico serch_icomobile">
                                                      <img src="images/Vector_search_icon.svg.png" alt="" />
                                                   </button>
   
                                                   <input
                                                      type="text"
                                                      className="google_serach_input google_serach_input_mobile p-0"
                                                      value={mockup.url ? formatUrl(mockup.url) : "brandname"}
                                                   />
                                                   <div className="google-search-close me-2">
                                                      <svg
                                                         focusable="false"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 24 24"
                                                      >
                                                         <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                                      </svg>
                                                   </div>
                                                </div>
                                                <div className="end_area">
                                                   <button href="#"className="goog_btn mic_mobile">
                                                      <img src="images/Google_mic.svg" alt="" />
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="right ">
                                             {/* <div className="search_labs">
                                             <img src="images/serachlabs.png" alt="" width="25px" />
                                          </div> */}
                                       
                                          </div>
                                       </div>
                                       <div className="tabsmobile">
                                          <div className="left_tabs_mobile">
                                             <button href="#"className="tab_btn_mobile active">All</button>
                                             <button href="#"className="tab_btn_mobile">Images</button>
                                             <button href="#"className="tab_btn_mobile">Videos</button>
                                             <button href="#"className="tab_btn_mobile">News</button>
                                             <button href="#"className="tab_btn_mobile">Shopping</button>
                                             <button href="#"className="tab_btn_mobile">Maps</button>
                                             <button href="#"className="tab_btn_mobile">Books</button>
                                             <button href="#"className="tab_btn_mobile d-none">
                                                <span>
                                                   <i className="bx bx-dots-vertical-rounded"></i>
                                                </span>
                                                More
                                             </button>
                                          </div>
                                          <div className="right_tabs d-none">
                                             <button className="tab_btn ">Tool</button>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="capsul_tab_mobile">
                                       <button href="#"className="capsul_mobile">Free</button>
                                       <button href="#"className="capsul_mobile">Reviews</button>
                                       <button href="#"className="capsul_mobile">Open Now</button>
                                       <button href="#"className="capsul_mobile">Clips</button>
                                       <button href="#"className="capsul_mobile">Pricing</button>
                                    </div>
                                    <div className="google_serach_result_mobile">
                                       <div className="sponsored-text2_mobile">
                                          <span>Sponsored</span>
                                       </div>
                                       <div className="serach_item">
                                          <div className="brand_logo_img brand_logo_img_mobile">
                                             <img src="images/smalllogo.png" alt="" />
                                          </div>
                                          <div className="brand_info">
                                             <div className="barnd_name_mobile">
                                                <h6 className="m-0 ">
                                                   {mockup?.display_url
                                                      ? mockup?.display_url
                                                      : "yourwebsite.com"}
                                                </h6>
                                                <div className="link">
                                                   <p className="website_link_mobile">
                                                      {mockup?.url
                                                         ? mockup?.url
                                                         : "#"}
                                                   </p>
                                                   <a href="#" className="modal_dot_mobile">
                                                      <i className="bx bx-dots-vertical-rounded"></i>
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="serach_result_main_mobile">
                                          <a
                                             href="#"
                                             className=""
                                          
                                                onclick="return false;"
                                          >
                                             <h1>
                                                {mockup?.headline
                                                   ? mockup?.headline
                                                   : " PPCC - Generate Google Ads - Try for Free "}
                                             </h1>
                                          </a>
                                          {/* <span className="brand_name_highlisght">Munch</span> */}
                                          <p className="info_mobile">
                                             {mockup?.description
                                                ? mockup?.description
                                                : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                                          </p>
                                       </div>
                                       {mockup?.sitelinks?.length >= 0
                                          ? mockup?.sitelinks?.map((item, index) => (
                                             <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''}`}>
                                             <div className="iCzAIb_mobile">
                                                <div className="d-flx mock-site-link">
                                                   <a href="#" className="" onclick="return false;">{item.title}</a><span class="arrowsitelink">›</span>
                                                   <div className="icon d-none-icon">
                                                      <i className="bx bx-chevron-right"></i>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                            ))
                                          : Mocksite?.map((item, index) => (
                                               <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''}`}>
                                                  <div className="iCzAIb_mobile">
                                                     <div className="d-flx mock-site-link">
                                                        <a className="" onclick="return false;">{item.site_text}</a>
                                                        <div className="icon d-none-icon">
                                                           <i className="bx bx-chevron-right"></i>
                                                        </div>
                                                     </div>
                                                     <p className="info m-0">{item.sitePara}</p>
                                                  </div>
                                               </div>
                                            ))}
                                    </div>


{/* SECOND SEARCH RESULT */}
<div className="google_serach_result_mobile google_serach_result_mobile2">
            
                                       <div className="serach_item">
                                          <div className="brand_logo_img brand_logo_img_mobile">
                                             <img src="images/smalllogo.png" alt="" />
                                          </div>
                                          <div className="brand_info">
                                             <div className="barnd_name_mobile">
                                                <h6 className="m-0 ">
                                                   {mockup?.display_url
                                                      ? mockup?.display_url
                                                      : "ppcc.ai"}
                                                </h6>
                                                <div className="link">
                                                   <p className="website_link_mobile">
                                                      {mockup?.url
                                                         ? mockup?.url
                                                         : "# "}
                                                   </p>
                                                   <a href="#" className="modal_dot_mobile">
                                                      <i className="bx bx-dots-vertical-rounded"></i>
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="serach_result_main_mobile">
                                          <a
                                             href="#"
                                             className=""
                                         
                                                onclick="return false;"
                                          >
                                             <h1>
                                                {mockup?.headline
                                                   ? mockup?.headline
                                                   : " PPCC AI - Generate Google Ads - Try for Free "}
                                             </h1>
                                          </a>
                                          {/* <span className="brand_name_highlisght">Munch</span> */}
                                          <p className="info_mobile info_mobile2">
                                             {mockup?.description
                                                ? mockup?.description
                                                : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                                          </p>
                                             <div class="snippetdivmobile"><a href="#"><span class="snippetmobile">Your</span></a><a href="#"><span class="snippetmobile">Callouts</span></a><a href="#"><span class="snippetmobile">Here</span></a><a href="#"><span class="snippetmobile">Try and See for Free</span></a></div>
                                       </div>
                                       
                                          
                                    </div>


                                               
                                 </div>
                              </div>
</div>
                        </div>
                     </AnimatedSection>
                  </section>
)}
            </div>
            {/* Second  Section  */}
               <section className="extract-sc">
   <div className="container">
      <div className="row align-items-center justify-content-between extract-sc-in">
         <div className="col-md-7 order-md-1 order-2 lft">
            <AnimatedSection>
               <h2 className="sec-title extracttile">Download <span class="custom-arrow">&rarr;</span> Import <span class="custom-arrow">&rarr;</span> Publish</h2>
               <p className="my-3 extractp">
                  Export your generated ads to a Google sheet with a few clicks and directly upload it into the Google ads Editor.
               </p>
               <div className="btn-sc d-md-flex align-items-center nohovereffect extractsett">
                  <div>
                     <button onClick={handleExtract} className="btn btn-without-effect btn-sq me-3 extractbtn">
                        Download <i class="csv-icon" >CSV</i>
                     </button>
                  </div>
                  
                  {isAuthenticated ? (
   <div className="outer-more-settings">
      <span
   className="p-16 moresettingstxt"
   style={{ marginLeft: "10px" }}
   onClick={() => {
      setIsOpen(!isOpen);

      // Add spacing after 1.5s if opening
      if (!isOpen) {
         setTimeout(() => {
            const screenWidth = window.innerWidth;

            // Determine padding based on screen size
            let padding;
            if (screenWidth < 768) {
               padding = 30; // Mobile
            } else if (screenWidth < 1200) {
               padding = 70; // Laptop
            } else {
               padding = 100; // Desktop
            }

            setDropdownPadding(padding);
         }, 1500);
      } else {
         // Reset spacing when closing
         setDropdownPadding(0);
      }
   }}
>
   More Settings 
   <i
      className="dropdown-icon"
      style={{
         display: "inline-block",
         marginLeft: "7px",
         transition: "transform 0.3s ease, vertical-align 0.3s ease",
         transform: isOpen ? "rotate(48deg)" : "rotate(225deg)",
         verticalAlign: isOpen ? "3px" : "-2px",
      }}
   ></i>
</span>

      {/* <MoreSetting handleCheckChange={(e) => { handleCheckChange(e) }} checkedItems={checkedItems} /> */}
   </div>
) : (
   <div className="outer-more-settings">
      <span className="p-16 moresettingstxt nextinstead" style={{ marginLeft: "10px",scale }}>
         & Launch in 60 sec
      </span>
   </div>
)}
               </div>
            </AnimatedSection>
         </div>
         <div className="col-md-5 order-md-2 order-1 rgt mt-md-0 mt-5 ext-camp-data-image">
            <AnimatedSection>
               <img src="/images/extract.png" alt="" className="w-100 h-100 object-fit-cover" />
            </AnimatedSection>
         </div>
      </div>
      {isOpen && (
      <div class="container moresettingscon">
      <div class="extractcost">
         <p class="p-16" style={{ marginLeft: "15px" }}>
            Estimated cost: <span style={{ color: "white" }}>{totalCost || 250} credits</span>
            <div class="tooltip-container">
               <span class="question-mark">?</span>
               <div class="tooltip-text">Credits are the in-app currency you spend to generate, refine, and download ad campains.</div>
            </div>
         </p>
      </div>
      <div ref={scope} class="newitemssettings" style={{ marginTop: "50px", marginLeft: "10px" }}>
         <div className="label-sc">
            <input type="checkbox" name="all" onChange={handleCheckChange} checked={checkedItems.all} />
            <label htmlFor="">All</label>
         </div>
         <div className="label-sc">
            <input
               type="checkbox"
               name="headline"
               onChange={handleCheckChange}
               checked={checkedItems.headline}
            />
            <label htmlFor="">Headlines</label>
         </div>
         <div className="label-sc">
            <input
               type="checkbox"
               name="descriptions"
               onChange={handleCheckChange}
               checked={checkedItems.descriptions}
            />
            <label htmlFor="descriptions">Descriptions</label>
         </div>
         <div className="label-sc">
            <input
               type="checkbox"
               name="sitelinks"
               checked={checkedItems.sitelinks}
               onChange={(e) => {
                  handleCheckChange(e);
               }}
            />
            <label htmlFor="sitelinks">Sitelinks</label>
         </div>
         <div class="label-sc">
            <input
               type="checkbox"
               name="callout"
               checked={checkedItems.callout}
               onChange={(e) => {
                  handleCheckChange(e);
               }}
            />
            <label htmlFor="callout">Callouts</label>
         </div>
         <div className="label-sc">
            <input
               type="checkbox"
               name="snippets"
               checked={checkedItems.snippets}
               onChange={(e) => {
                  handleCheckChange(e);
               }}
            />
            <label htmlFor="snippets">Snippets</label>
         </div>
         <div className="label-sc1 align-items-center" style={{ fontSize: "14px", marginLeft: "20px" }}>
            <label htmlFor="" className="me-2">
               Amount of ads:
            </label>
            <input
               placeholder="no."
               className="amountofads"
               type="number"
               name="number_of_ads"
               value={numberOfAds}
               onChange={(e) => {
                  const value = Math.max(1, Number(e.target.value) || 1); // Enforce minimum of 1
                  setNumberOfAds(value);
              }}
               pattern="\d*"
               min="1"
            />
         </div>
      </div>
   </div>
               )}
   </div>
</section>

            <div
               className={`modal fade ${showModal ? "active" : ""}`}
               id="myModal"
               tabIndex="-1"
               aria-labelledby="myModalLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content p-4">
                     <button type="button" className="close ms-auto" onClick={handleClose} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                     </button>

                     <div className="modal-body">
                        {/* <p className="mb-2 text-black"><strong>Ad.</strong> example.business.com</p>
              <h3 className="mb-2 text-black"><strong>Modern Design.Co</strong></h3>
              <p className="mb-0 text-black">Ther best modal</p> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default Homepage;
