import React from "react";
import FadeANimatedSection from "./FadeANimatedSection";

const BackdropAnimation = ({ style, singleElement = false, delay = 0 }) => {
  return (
    <FadeANimatedSection delay={delay}>
      <div className="backdrop-main" style={style || {}}>
        <div className="backdrop-elm" />
        {!singleElement && <div className="backdrop-elm-2" />}
      </div>
    </FadeANimatedSection>
  );
};

export default BackdropAnimation;
